.Mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(39,39,39,0.8);
    transition: opacity 0.2s linear;
}

.Upload img {
    display: none;
}

.UploadContent {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 50px;
    color: white;
    text-align: center;
}

.UploadMessage {
    font-size: 24px;
}

.UploadContent canvas {
    margin: 45px 0 5px 0;
}

.UploadControls {
    margin-top: 40px;
}

.UploadRetry {
    font-family: "Arial", sans-serif;
    font-size: 18px;
    background-color: var(--button-color);
    color: var(--button-text-color);
    border: 0;
    width: 144px;
    height: 46px;
    border-radius: 4px;
    margin: 0 12px 0 12px;
    cursor: pointer;
}
.UploadRetry:hover {
    background-color: var(--button-color-hover);
}
.UploadRetry:active {
    background-color: var(--button-color-press);
}

.UploadCancel {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 18px;
    background-color: transparent;
    color: white;
    border: 2px solid white;
    width: 144px;
    height: 46px;
    border-radius: 4px;
    margin: 0 12px 0 12px;
    cursor: pointer;
}
.UploadCancel:hover {
    background-color: rgba(255,255,255,0.1);
}
.UploadCancel:active {
    background-color: rgba(0,0,0,0.1);
}

.UploadMore {
    font-family: "Arial", sans-serif;
    font-size: 18px;
    background-color: var(--button-color);
    color: var(--button-text-color);
    border: 0;
    width: 184px;
    height: 46px;
    border-radius: 4px;
    margin: 0 12px 0 12px;
    cursor: pointer;
}
.UploadMore:hover {
    background-color: var(--button-color-hover);
}
.UploadMore:active {
    background-color: var(--button-color-press);
}