.Toolbar {
    background-color: #fafafa;
    height: 77px;
    min-height: 77px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
    border: 1px solid #c9c9c9;
}

.ToolbarLeft {
    padding: 0 15px 0 40px;
}

.ToolbarMiddle {
    flex: 1;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 15px;
    color: #5f5f5f;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ToolbarRight {
    padding: 0 40px 0 0;
}

.ToolbarBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    color: var(--button-text-color);
    border-radius: 3px;
    height: 42px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ToolbarBtn img {
    vertical-align: middle;
    margin-right: 7px;
}

.ToolbarAdd {
    background-color: var(--button-color);
    width: 131px;
}
.ToolbarAdd:hover {
    background-color: var(--button-color-hover);
}
.ToolbarAdd:active {
    background-color: var(--button-color-press);
}

.ToolbarUpload {
    background-color: #ffba60;
    color: black;
    width: 143px;
}
.ToolbarUpload:hover {
    background-color: #ffd297;
}
.ToolbarUpload:active {
    background-color: #dd9547;
}

.ToolbarUploadDisabled {
    background-color: #cccccc;
    color: #888888;
    width: 143px;
    cursor: not-allowed;
}
