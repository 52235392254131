.SigninTitle {
    font-family: "Montserrat-ExtraBold", sans-serif;
    font-size: 41px;
}

.SigninSubtitle {
    font-family: "Montserrat-ExtraBold", sans-serif;
    font-size: 25px;
}

.SigninDivide {
    width: 161px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.4);
    margin: 15px 0 30px 0;
}

.SigninInfo {
    margin-bottom: 70px;
}

.SigninInfo td:nth-child(1) {
    text-align: center;
    padding: 15px 0 15px 0;
}

.SigninInfo td:nth-child(2) {
    padding: 10px 0 10px 17px;
}

.SigninUser, .SigninEmail {
    font-size: 17px;
}

.SigninLogin {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    background-color: var(--button-color);
    color: var(--button-text-color);
    border: 0;
    border-radius: 4px;
    padding: 10px 0;
    width: 100%;
    margin-top: 15px;
    cursor: pointer;
}
.SigninLogin:hover{
    background-color: var(--button-color-hover);
}
.SigninLogin:active{
    background-color: var(--button-color-press);
}

.SigninLoginDisabled {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    background-color: #cccccc;
    color: #888888;
    border: 0;
    border-radius: 4px;
    padding: 10px 0;
    width: 100%;
    margin-top: 15px;
    cursor: not-allowed;
}

.SigninInstr {
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    font-weight: normal;
    margin-bottom: 30px;
}
.SigninError button,
.SigninInstr button {
    font-weight: bold;
    color: black;
    border: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
}

.SigninInstr button:active {
    color: var(--button-color-press);
}

.SigninError button {
    color: red;
}

.SigninError button:active {
    color: #800000;
}

.SigninCreateInstr {
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    font-weight: normal;
    margin-bottom: 17px;
}

.SigninCreateInstr button {
    font-weight: bold;
    color: black;
    border: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
}

.SigninCreateInstr button:active {
    color: var(--button-color-press);
}

.EmptyListBody a:active {
    color: var(--button-color-press);
}

.SigninUser input[type=email],
.SigninUser input[type=text],
.SigninEmail input[type=email]{
    margin: 9px 0 0 0;
    padding: 0 5px;
    border: 1px solid #707070;
    border-radius: 4px;
    height: 38px;
    width: 370px;
    font-size: 16px;
}

.SigninUser input.Invalid,
.SigninEmail input.Invalid {
    border-color: red;
}

.SigninUser tr:nth-child(2) input[type=text]{
    width: 167px;
}

.SigninUser tr:nth-child(2) td:nth-child(2) {
    text-align: right;
}

.SigninUser td,
.SigninEmail td {
    padding: 15px 0 0 0;
}

.SigninUserField {
    display: inline-block;
    text-align: left;
}

.SigninUserField .Error {
    color: red;
    font-size: 9pt;
    padding-left: 5px;
    margin-top: 2px;
}

.SigninError {
    color: rgba(229, 8, 8, 1);
    border: 1px solid rgba(229, 8, 8, 1);
    background-color: rgba(229, 8, 8, 0.05);
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    padding: 15px;
    margin-top: 30px;
}

.SigninArea {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    transition: opacity 0.15s linear;
    background-color: #efefef;
    font-size: 17px;
}

.SigninLeft {
    background: var(--accent-color-primary); /* Old browsers */
    background: -moz-linear-gradient(top, var(--accent-color-primary) 0%, var(--accent-color-secondary) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, var(--accent-color-primary) 0%, var(--accent-color-secondary) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, var(--accent-color-primary) 0%, var(--accent-color-secondary) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    box-shadow: rgba(0,0,0,0.3) 1px 0 13px;

    display: flex;
    align-items: center;
    color: white;
    padding-left: 53px;
    padding-right: 53px;
    min-width: 470px;
}

.SigninRight {
    font-family: "Arial", sans-serif;
    font-size: 20px;
    color: black;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PageNotFound {
    font-family: "Montserrat-Medium", sans-serif;
    text-align:center;
}

.PageNotFoundMsg {
    margin: 50px 0;
}