@keyframes DropImgAnim {
    0% {
        top: 0;
    }
    25% {
        top: -20px;
    }
    75% {
        top: 20px;
    }
    100% {
        top: 0;
    }
}

.DropZone {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.DropZoneContent {
    font-family: "Arial", sans-serif;
    font-size: 34px;
    width: 100%;
    height: 100%;
    background-color: #f3f9ff;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    border: 4px solid var(--accent-color-primary);
    box-shadow: rgba(0,0,0,0.16) 8px 8px 13px;
    border-radius: 3px;
    pointer-events: none;
    overflow: hidden;
}

.DropZoneContentInvalid {
    border-color: red;
}

.DropZoneContent img {
    width: 250px;
    height: 250px;
    margin-bottom: 30px;
    animation: DropImgAnim infinite 1s linear;
    position: relative;
    pointer-events: none;
}

.DropZoneContentInvalid img {
    animation: none;
}

.DropZoneWarning {
    font-size: 18pt;
    color: red;
}