.AccountModalMask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(39,39,39,0.8);
    transition: opacity 0.2s linear;
}

.AccountModal {
    font-family: "Arial", sans-serif;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #707070;
    padding: 30px;
    width: 520px;
}

.AccountModalTitle {
    font-size: 26px;
    margin-bottom: 30px;
}

.AccountModalTitle img {
    width: 35px;
    height: 35px;
    vertical-align: middle;
    margin-right: 15px;
}

.AccountModalContent {
    color: black;
    margin-bottom: 50px;
}

.AccountModalContent a {
    font-weight: bold;
    color: black;
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
}

.AccountModalContent a:active {
    color: #277dde;
}

.AccountModal input[type=button] {
    border: 0;
    border-radius: 4px;
    background-color: var(--button-color);
    color: var(--button-text-color);
    width: 375px;
    height: 42px;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    cursor: pointer;
}

.AccountModal input[type=button]:hover {
    background-color: var(--button-color-hover);
}
.AccountModal input[type=button]:active {
    background-color: var(--button-color-press);
}

.AccountModalFooter {
    text-align: center;
}