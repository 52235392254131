.Notify {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 18px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #1f908c;
    color: white;
    padding: 25px 15px;
    transition: opacity 0.15s linear;
}

.Notify img {
    width: 24px;
    height: 18px;
    vertical-align: middle;
    margin-right: 10px;
}