html, body {
    height: 100%;
    overflow: hidden;
}

.Header {
    background-color: var(--accent-color-primary);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    height: 89px;
    min-height: 89px;
    font-family: "Montserrat-Medium", sans-serif;
    font-size: 20px;
}

.HeaderTitle {
    display: flex;
    align-items: center;
    padding-left: 5px;
    font-weight: bold;
    flex: 1;
    overflow: hidden;
}

.HeaderTitle div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.HeaderSubTitle {
    font-weight: normal;
    font-size: 10pt;
}

.HeaderTitle img {
    width: 50px;
    height: 50px;
    vertical-align: middle;
    margin: 0 15px 0 20px;
}

.HeaderUser {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    padding-right: 40px;
}

.HeaderUser button {
    font-weight: bold;
    color: white;
    border: 0;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
    margin-left: 20px;
}

.HeaderUser button:active {
    color: var(--button-color-press);
}