.ValidateTokenMask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(39,39,39,0.8);
    transition: opacity 0.2s linear;
}

.ValidateToken {
    font-family: "Arial", sans-serif;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #707070;
    padding: 30px;
}

.ValidateTokenTitle {
    font-size: 22px;
}