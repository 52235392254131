.ListView {
    overflow: auto;
    flex: 1;
    padding: 20px;
}

.EmptyList {
    flex: 1;
    padding: 30px 40px 0 40px;
}

.EmptyListArea {
    width: 100%;
    font-size: 16pt;
    border: 2px dashed var(--accent-color-secondary);
    border-radius: 4px;
    color: black;
    text-align: center;
    background-color: #f3f9ff;
    padding: 60px 0 60px 0;
}

.EmptyListTitle {
    font-family: "Arial", sans-serif;
    font-size: 30px;
    margin-bottom: 35px;
}

.EmptyListBody {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 20px;
    margin-bottom: 35px;
    line-height: 30px;
}

.EmptyListBodyMessage {
    font-weight: bold;
    margin-top: 15px;
    font-size: 22px;
}

.EmptyListBody button {
    font-weight: bold;
    color: black;
    border: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
}

.EmptyListBody button:active {
    color: var(--button-color-press);
}

.EmptyListFooter {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 20px;
    color: rgba(0,0,0,0.41);
}

.ListViewInfoArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 7px;
}

.ListViewInfo {
    flex: 1;
    text-align: center;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
}

.ListView input[type=button] {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    width: 122px;
    height: 42px;
    background-color: white;
    border: 1px solid rgba(112, 112, 112, 0.25);
}

.ListView input[type=button]:hover {
    background-color: #dfdfdf;
}

.ListView input[type=button]:active {
    background-color: #cfcfcf;
}

.ListViewTable {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    width: 100%;
}

.ListViewTable th {
    text-align: left;
    padding: 3px 7px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    color: black;
    border: 1px solid #a7a7a7;
    border-left: 0;
    border-right: 0;
    background-color: #e8e8e8;
    font-weight: normal;
}

.ListViewTable th.SortedAsc {
    font-weight: bold;
}

.ListViewTable th.SortedDesc {
    font-weight: bold;
}

.ListViewTable th:nth-child(1) {
    border-left: 1px solid #a7a7a7;
    padding-left: 25px;
}

.ListViewTable th:nth-child(2) {
    width: 60px;
}

.ListViewTable th:nth-child(3) {
    width: 100px;
}

.ListViewTable th:nth-child(4) {
    width: 60px;
    border-right: 1px solid #a7a7a7;
}

.ListViewTable th img {
    vertical-align: middle;
    position: relative;
    top: -2px;
}
.ListViewTable th img.SortedAsc {
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}

.ListViewTable td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 7px;
    border-bottom: 1px solid #a7a7a7;
}

.ListViewTable td button {
    color: #db3b21;
    border: 0;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
}

.ListViewTable td button:active {
    color: #db594b;
}

.ListViewTable td:nth-child(1) {
    border-left: 1px solid #a7a7a7;
    padding-left: 25px;
}

.ListViewTable td:nth-child(2) {
    width: 60px;
}

.ListViewTable td:nth-child(3) {
    width: 100px;
}

.ListViewTable td:nth-child(4) {
    /*width: 60px;*/
    border-right: 1px solid #a7a7a7;
    padding-right: 20px;
    /*padding: 0;*/
    max-width: min-content;
}